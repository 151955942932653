<template>
    <div>
        <page-header :page-title="t('ATTRIBUTES.INDEX')"></page-header>
        <div class="task-section">
            <div class="row no-gutters">
                <div class="col-2">
                    <div class="labels-container">
                        <div class="slimScrollDiv"
                             style="position: relative; overflow: hidden; width: auto; height: 100%;">
                            <div class="lablesContainerScroll" style="overflow: hidden; width: auto; height: 100%;">
                                <div class="filters-block">
                                    <h5>Cadastro de:</h5>
                                    <div class="filters">
                                        <span v-for="(iten, key) in tables" :key="key">
                                            <a href="#"
                                               @click="index(iten.id)"
                                               :class="{'active': iten.active}">
                                            <i class="icon-users"/> {{ iten.text }}
                                        </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9 col-8">
                    <div class="tasks-container ">
                        <div class="nav">
                            <div class="tasks-header d-flex border" :class="setBackgroud(1)" @click="indexUpperCase(1)">
                                <div class="nav-item">
                                    <a class="nav-link" :class="setActive(1)"><h6>Campos
                                        Obrigatórios</h6></a></div>
                            </div>
                            <div class="tasks-header d-flex border" :class="setBackgroud(2)" @click="indexUpperCase(2)">
                                <div class="nav-item">
                                    <a class="nav-link" :class="setActive(2)"><h6>Campos em Caixa
                                        Alta</h6></a>
                                </div>
                            </div>
                        </div>
                        <div class="slimScrollDiv"
                             style="position: relative; overflow: hidden; width: auto; height: 100%;">
                            <div class="tasksContainerScroll" style="overflow: auto; width: auto; height: 100%;">
                                <div class="row no-gutters justify-content-center">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                         v-if="headerActive === 1">
                                        <section class="task-list">
                                            <div class="row gutters pl-3 pt-3 w-100">
                                                <div class="col-4 w-100" v-for="(iten, key) in items" :key="key">
                                                    <div class="card">
                                                        <label :class="isRequiredClass(iten.is_required)"
                                                               class="ml-2 pt-2"
                                                        >{{
                                                                iten.traduction_name
                                                            }}:</label>
                                                        <div class="border-bottom"></div>
                                                        <div class="custom-control custom-switch ml-2 pt-2 pb-2">
                                                            <input type="checkbox" v-model="iten.is_required"
                                                                   @change="changeAtr(iten.id)"
                                                                   class="custom-control-input" :id="`atr${iten.id}`">
                                                            <label data-cy="profile_update_about_r2"
                                                                   class="custom-control-label"
                                                                   :for="`atr${iten.id}`">obrigatorio</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                         v-if="headerActive === 2">
                                        <section class="task-list">
                                            <div class="row gutters pl-3 pt-3 w-100">
                                                <div class="col-4 w-100" v-for="(iten, key) in itemsUpper" :key="key">
                                                    <div class="card">
                                                        <label class="ml-2 pt-2">{{
                                                                castNameItemUpper(iten)
                                                            }}:</label>
                                                        <div class="border-bottom"></div>
                                                        <div class="custom-control custom-switch ml-2 pt-2 pb-2">
                                                            <input type="checkbox" v-model="iten.is_upper_case"
                                                                   @change="changeAtrUpper(iten.id)"
                                                                   class="custom-control-input" :id="`atr${iten.id}`">
                                                            <label data-cy="profile_update_about_r2"
                                                                   class="custom-control-label"
                                                                   :for="`atr${iten.id}`">Caixa Alta</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../components/layouts/pageHeader';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import AttibutesValidations from '../../services/AttibutesValidations';
import AttributesUpperCase from '../../services/AttributesUpperCase';

export default {
    name: "indexAtributesComponent",
    components: {PageHeader},
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            items: [],
            itemsUpper: [],
            tables: [],
            headerActive: 1,
        }
    },
    mounted() {
        this.listTablesValidations();
    },
    methods: {
        castNameItemUpper(item){
            if (item.is_upper_case){
                return item.traduction_name.toUpperCase();
            }
            return item.traduction_name;
        },
        setBackgroud(context) {
            if (context === this.headerActive) {
                return 'background-active-header';
            }
        },
        setActive(context) {
            if (context === this.headerActive) {
                return 'active';
            }
        },
        listTablesValidations() {
            this.$store.commit('changeLoading', true);
            AttibutesValidations.listAllTables().then(resp => {
                this.tables = resp.data;
                this.index(this.tables[0].id);
            }).catch(() => {
                this.tables = [];
                this.$store.commit('changeLoading', false);
            })
        },
        index(id) {
            this.headerActive = 1;
            this.$store.commit('changeLoading', true);
            AttibutesValidations.getByTableId(id).then(resp => {
                this.items = resp.data;
                this.tables.forEach(i => {
                    if (i.id == id) {
                        i.active = true;
                    } else i.active = false;
                })
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.items = [];
                this.$store.commit('changeLoading', false);
            })
        },
        indexUpperCase(context) {
            this.headerActive = context;
            let id = this.tables.find(i => i.active).id;
            this.$store.commit('changeLoading', true);
            AttributesUpperCase.getByTableId(id).then(resp => {
                this.itemsUpper = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.itemsUpper = [];
                this.$store.commit('changeLoading', false);
            })
        },
        isRequiredClass(bool) {
            if (bool) return 'required';
            return '';
        },
        changeAtr(id) {
            this.$store.commit('changeLoading', true);
            AttibutesValidations.updateRequired(id).then((resp) => {
                let id = resp?.data?.id;
                this.items.forEach(i => {
                    if (i.id === id) {
                        i.is_required = resp.data.is_required;
                    }
                })
                this.toast.success(this.t('GENERAL.UPDATED_SUCCESSFULL'));
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.items.forEach(i => {
                    if (i.id === id) {
                        i.is_required = !i.is_required;
                    }
                })
                this.$store.commit('changeLoading', false);
            })
        },
        changeAtrUpper(id) {
            this.$store.commit('changeLoading', true);
            AttributesUpperCase.updateUpperCase(id).then((resp) => {
                let id = resp?.data?.id;
                this.itemsUpper.forEach(i => {
                    if (i.id === id) {
                        i.is_required = resp.data.is_required;
                    }
                })
                this.toast.success(this.t('GENERAL.UPDATED_SUCCESSFULL'));
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.items.forEach(i => {
                    if (i.id === id) {
                        i.is_required = !i.is_required;
                    }
                })
                this.$store.commit('changeLoading', false);
            })
        }
    }
}
</script>

<style scoped>
.background-active-header {
    background: #eff1f5;
    border-bottom-color: #0e5e4e !important;
    border-bottom-width: 2px !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>