import {http} from './config';
export default {
    getByContext(context)
    {
        return http.get(`api/v1/atributos/contexto/${context}`);
    },
    publicGetByContext(context, companyId)
    {
        return http.get(`api/v1/publica/atributos/contexto/${context}`,
                {headers: {'company-id': companyId}});
    },
    getByTableId(id)
    {
        return http.get(`api/v1/atributos/tabela/${id}`);
    },
    updateRequired(id)
    {
       return http.patch(`api/v1/atributos/alterarObrigatoriedade/${id}`);
    },
    listAllTables()
    {
        return http.get(`api/v1/tabelas/listar`);
    },
}