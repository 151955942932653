import {http} from './config';
export default {
    getByTableId(id)
    {
        return http.get(`api/v1/atributos/caixaAlta/tabela/${id}`);
    },
    updateUpperCase(id)
    {
        return http.patch(`api/v1/atributos/alterarCaixaAlta/${id}`);
    },
}